<template>
  <div class="g-section">
    <div class="g-wrap flex-column">
      <content-header :title-list="titleList" />
      <div class="from-content-wrap" v-loading="loading">
        <!-- <job-form :id="$route.query.id" :isView="true"/> -->
        <div class="cont-top">
          <div class="top-fir">
            <div class="fir-left">{{ info.Name }}</div>
            <div class="fir-right">
              <!-- <div class="wx-share">
                <i class="iconfont iconfenxiang contactus-icon"></i>微信扫码分享
                <div class="share-tooltip">
                  <div class="share-tooltip-qrcode" id="qrcode">
                    <vue-qr :text="downloadData.url" :margin="0" colorDark="#000" colorLight="#fff" :size="114"></vue-qr>
                  </div>
                  <div class="share-tooltip-text">打开微信， 扫码分享</div>
                </div>
              </div> -->
              <!-- <el-button
                @click=""
                style="width: 88px"
                type="primary"
                plain
                size="small"
                >在线沟通</el-button
              >
              <div v-if="info.IsDelivered" type="info" class="grayBtn">
                已申请
              </div>
              <el-button
                v-else
                type="primary"
                style="width: 88px;margin-left: 8px;"
                size="small"
                @click=""
                >申请岗位</el-button
              > -->
            </div>
          </div>
          <div class="top-sec">
            <div class="sec-left">
              <span class="orange"
                >{{ info.SalaryMin }}K-{{ info.SalaryMax }}K</span
              >
              <div class="line"></div>
              <span>{{ info.WorkPlace }}</span>
              <div class="line"></div>
              <span>{{ info.EducationDescription }}</span>
              <div class="line"></div>
              <span>{{ info.ExperienceDescription }}</span>
              <div class="line"></div>
              <span>招{{ info.RecruitCount }}人</span>
            </div>
            <div class="sec-right" v-if="info.PublishTime">
              {{ info.PublishTime | timeFilter }} 发布
            </div>
          </div>
        </div>
        <div class="cont-bot">
          <div class="bot-left">
            <div class="title">
              <span></span>
              员工福利
            </div>
            <div class="fl-box">
              <div
                class="tag"
                v-for="(item, index) in info.RecruitJobTagList"
                :key="index"
              >
                {{ item.Name }}
              </div>
            </div>
            <div class="title">
              <span></span>
              职位描述
            </div>
            <div class="mx-box">{{ info.JobDescription }}</div>
            <div class="title">
              <span></span>
              联系方式
            </div>
            <div class="wp-box">{{ info.Address }}</div>
          </div>
          <div class="bot-right">
            <div class="title">
              <span></span>
              公司信息
            </div>
            <img :src="enterpriseInfo.LogoFullPath" class="entLogo" alt="" />
            <div class="entName">{{ enterpriseInfo.EnterpriseName }}</div>
            <div class="ent-info">
              <i class="iconfont iconqiyeleixing contactus-icon"></i
              >{{ enterpriseInfo.NatureName }}
            </div>
            <div class="ent-info">
              <i class="iconfont iconsuoshuhangye contactus-icon"></i
              >{{ enterpriseInfo.IndustryName }}
            </div>
            <div class="ent-info">
              <i class="iconfont iconqiyerenshu contactus-icon"></i
              >{{ enterpriseInfo.ScaleName }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JobForm from "./components/JobForm";
import ContentHeader from "@/components/Common/ContentHeader";
import { getInfo } from "@/api/job";
import { getInfo as getEnterpriseInfo } from "@/api/enterprise/enterpriseInfo";
import VueQr from "vue-qr";
import { ShareLink } from "@/utils/constant";
export default {
  components: { JobForm, ContentHeader, VueQr },
  filters: {
    timeFilter: function(time) {
      let timearr = time
        .replace(" ", ":")
        .replace(/\:/g, "-")
        .split("-");
      // let timestr = "" + timearr[1] + "月" + timearr[2] + "日\t" + timearr[3] + ":" + timearr[4] + ""
      let timestr = "" + timearr[0] + "-" + timearr[1] + "-" + timearr[2] + "";
      return timestr;
    }
  },
  data() {
    return {
      loading: false,
      titleList: [
        {
          text: "岗位招聘",
          isCur: false,
          link: ""
        },
        {
          text: "详情",
          isCur: true,
          link: ""
        }
      ],
      info: {},
      enterpriseInfo: {},
      downloadData: {
        url: ""
      }
    };
  },
  created() {
    this.init();
    if (process.env.ENV == "production") {
      this.downloadData.url =
        ShareLink.Prod + "/pagesB/job/jobDetail?id=" + this.$route.query.id;
    } else {
      this.downloadData.url =
        ShareLink.Dev + "/pagesB/job/jobDetail?id=" + this.$route.query.id;
    }
  },
  methods: {
    init() {
      this.loading = true;
      getInfo(this.$route.query.id).then(res => {
        this.info = res.Result;
        getEnterpriseInfo(res.Result.EnterpriseId).then(res => {
          this.enterpriseInfo = res.Result;
          this.loading = false;
        });
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.g-section {
  background: #f5f5f5;
}
.from-content-wrap {
  padding: 0;
}
.cont-top {
  height: 148px;
  border-bottom: 1px solid #eeeeee;
  padding: 40px 64px;
  box-sizing: border-box;
  .top-fir {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    .fir-left {
      font-size: 24px;
      font-weight: 600;
      color: #333333;
      line-height: 32px;
    }
    .fir-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .wx-share {
        cursor: pointer;
        height: 20px;
        font-size: 14px;
        color: #598DF3;
        margin-right: 16px;
        position: relative;
        .iconfont {
          margin-right: 6px;
          font-size: 16px;
        }
        .share-tooltip {
          display: none;
          top: 34px;
        }
        &:hover {
          .share-tooltip {
            display: block;
          }
        }
      }
    }
  }
  .top-sec {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
    .sec-left {
      display: flex;
      align-items: center;
      .line {
        height: 20px;
        width: 1px;
        background-color: #cccccc;
        margin: 0 16px;
      }
      span {
        font-size: 16px;
        color: #666666;
        line-height: 24px;
      }
      .orange {
        font-size: 20px;
        color: #ff6010;
        line-height: 28px;
      }
    }
    .sec-right {
      font-size: 16px;
      color: #cccccc;
      line-height: 24px;
    }
  }
}
.cont-bot {
  padding: 40px 64px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  .title {
    height: 20px;
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
    display: flex;
    span {
      display: inline-block;
      width: 4px;
      height: 20px;
      background: #598DF3;
      border-radius: 2px;
      margin-right: 8px;
    }
  }
  .bot-left {
    .fl-box {
      padding: 20px 0 32px;
      .tag {
        margin-right: 8px;
        display: inline-block;
        height: 24px;
        background: #fcfcfc;
        border-radius: 4px;
        border: 1px solid #dddddd;
        font-size: 14px;
        color: #999999;
        line-height: 24px;
        padding: 0 8px;
      }
    }
    .mx-box {
      margin: 16px 0 40px;
      font-size: 16px;
      color: #666666;
      line-height: 32px;
      padding-right: 48px;
    }
    .wp-box {
      margin: 16px 0 0;
      font-size: 16px;
      color: #666666;
      line-height: 32px;
    }
  }
  .bot-right {
    width: 168px;
    .entLogo {
      margin: 16px 40px 13px;
      width: 80px;
      height: 80px;
    }
    .entName {
      height: 24px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 24px;
      margin-bottom: 11px;
    }
    .ent-info {
      font-size: 16px;
      color: #999999;
      line-height: 24px;
      margin-top: 8px;
      .iconfont {
        font-size: 16px;
        margin-right: 12px;
      }
    }
  }
}
.grayBtn {
  width: 88px;
  height: 32px;
  background-color: #ccc;
  color: #fff;
  text-align: center;
  margin-left: 8px;
  line-height: 32px;
  height: 32px;
  font-size: 14px;
  border-radius: 4px;
}
</style>
